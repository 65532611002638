/** @jsx jsx */
import { jsx, Button, Grid, Flex, Styled, Container} from 'theme-ui'
import React, {createRef} from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Info from '../images/info.svg'
import Paper from "../images/ricepaper.jpg"


const IndexPage = () => {

  const gobble = createRef()

  const formSubmit = () => {
    gobble.current.play()
  }

  const {pug} = useStaticQuery(graphql`
  query {
    pug: file(relativePath: { eq: "cooked-turkey.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paper: file(relativePath: { eq: "ricepaper.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

  return(
    <Layout>
      <SEO title="Home" />
      <Grid columns={[1,1,2]} sx={{gridAutoColumns: "1fr", minHeight: "100vh", backgroundImage: `url(${Paper})`, backgrounRepeat: "repeat"}}>
        <Flex sx={{zIndex: "10", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", p:5}}>
          <div sx={{width: "100%", maxWidth: "small"}}>
            {/* <Img fluid={rex.childImageSharp.fluid} sx={{width: "100%", maxWidth: "800px"}} /> */}
            <Styled.h1 sx={{textAlign: ['center','center','left'], fontWeight: 'bold', textTransform: 'uppercase'}}>Turkey Birds</Styled.h1>
            <Styled.h2 sx={{my: 6, textAlign: ['center','center','left']}}>Order pasture-raised turkeys for your holiday get togethers.</Styled.h2>
            <form name="join-list" data-action="/get-started" method="post" data-netlify="true" data-netlify-honeypot="bot-field" sx={{display: "flex", zIndex: "10", backgroundColor: "light", minWidth: ["300px", "325px", "420px"], flexWrap: ["wrap", "wrap", "nowrap"], justifyContent: "space-between", borderRadius: "20px"}}>
              <input type="hidden" name="form-name" value="join-list" />
              <input name="email" type="email" sx={{minWidth: ["150px", "250px", "300px", "350px"], width: "100%", border: "none", px: 4, m: 4, fontSize: [0,0,1]}} placeholder="What's your email?" />
              <Button variant="primary" onClick={e => formSubmit()} sx={{m: 2, minWidth: "70px", width: "100%", borderRadius: "20px"}}>join list</Button>
              {/* <audio ref={gobble} src={Gobble} preload /> */}
            </form> 
            <Flex sx={{display: ["block","block","none"]}}>
              <Styled.h4 sx={{my: 6}}><img src={Info} width="15px" sx={{my: 0, py: 0, mr: 3}} />Note to turkey eaters: we will email when more turkeys are ready to pre-order.</Styled.h4>
            </Flex>
            <Styled.h4 sx={{display: ["none","none","block"], my: 6}}><img src={Info} width="15px" sx={{my: 0, py: 0, mr: 3}} />Note to turkey eaters: we will email when more turkeys are ready to pre-order.</Styled.h4>
          </div>
        </Flex>
        <Flex sx={{height: "100%", width: "100%", justifyContent: "flex-end", alignItems: "center"}}>
          <Img fluid={pug.childImageSharp.fluid} sx={{width: "100%", maxWidth: "600px"}} />
        </Flex>
      </Grid>
    </Layout>
  )
}

export default IndexPage
